import React, { useState, useEffect } from 'react';

const CountdownCard = ({ targetDate }) => {
  const targetTime = new Date(targetDate).getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const distance = targetTime - now;

    if (distance > 0) {
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, [targetTime]);

  return (
    <div className="parent-container" style={{ position: 'relative' }}>
      <div
        className='mb-4'
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          width: '100%',
          height: '130px',
          backgroundColor: '#FCB541',
          padding: '0.5em',
          borderRadius: '8px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          position: 'relative',
          bottom: '60px',
          margin: '0 auto',
          '@media (min-width: 768px)': {
            width: '525px',
            height: '105px',
            bottom: '40px',
            left: 'calc(50% - 262.5px)',
          }
        }}
      >
        <div style={{ width: "15em", textAlign: "center" }}>
          <p style={{
            fontFamily: 'Open Sans',
            fontWeight: 600,
            fontSize: "1.25em",
            lineHeight: "27.24px"
          }}>
            Count every second until our next Event (Ekadasha Rudra)
          </p>
        </div>
        <div className='d-flex justify-content-evenly' style={{
          width: "30em",
          fontFamily: 'Open Sans',
        }}>
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontSize: '40px', fontWeight: 700, color: '#000000' }}>{timeLeft.days}</span><br />
            <span style={{ fontSize: '18px', fontWeight: 400, color: '#000000' }}>Days</span>
          </div>
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontSize: '40px', fontWeight: 700, color: '#000000' }}>{timeLeft.hours}</span><br />
            <span style={{ fontSize: '18px', fontWeight: 400, color: '#000000' }}>Hours</span>
          </div>
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontSize: '40px', fontWeight: 700, color: '#000000' }}>{timeLeft.minutes}</span><br />
            <span style={{ fontSize: '18px', fontWeight: 400, color: '#000000' }}>Minutes</span>
          </div>
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontSize: '40px', fontWeight: 700, color: '#000000' }}>{timeLeft.seconds}</span><br />
            <span style={{ fontSize: '18px', fontWeight: 400, color: '#000000' }}>Seconds</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountdownCard;
